::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.rc-tooltip {
    padding: 6px 0 !important;

    display: flex;
    justify-content: center;
    align-items: center;
}

.rc-tooltip-inner {
    height: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e8e8fd !important;
    color: #42526e !important;
    font-weight: 500;
    font-size: 12px;
    box-shadow: none !important;
    padding: 4px 8px !important;
}

.rc-tooltip-arrow {
    transform: scale(1.5);
    border-top-color: #e8e8fd !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    border-top-color: #e8e8fd !important;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    right: -6px !important;
    border-left-color: #e8e8fd !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    left: -6px !important;
    right: 0 !important;
    border-right-color: #e8e8fd !important;
    border-top-color: transparent !important;
}
